import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import EcoSystemContentCard from "../../../../V2/Cards/EcoSystem/Content"

import { getUrlFromStoryblokLink, linkIsNotNull } from "@utils/storyblok"

export default function StoryblokEcoSystemContentCard({
  blok,
}: Storyblok.BlokProps<Storyblok.EcoSystemContentCard>) {
  return (
    <EcoSystemContentCard
      primaryBackgroundColor={blok.primaryBackgroundColor}
      secondaryBackgroundColor={blok.secondaryBackgroundColor}
      textColor={blok.textColor}
      superscriptText={blok.superscriptText}
      titleText={blok.titleText}
      descriptionText={blok.descriptionText}
      videoUrl={
        blok.videoUrl && linkIsNotNull(blok.videoUrl)
          ? getUrlFromStoryblokLink(blok.videoUrl)
          : ""
      }
      imageUrl={blok.image.filename}
      imageAlt={blok.image.alt}
      imageRounded={blok.imageRounded}
      backgroundImage={blok.backgroundImage}
      buttonColor={blok.ctaColor}
      buttonLink={
        blok.ctaLink && linkIsNotNull(blok.ctaLink)
          ? getUrlFromStoryblokLink(blok.ctaLink)
          : undefined
      }
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}
